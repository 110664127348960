<template>
  <div
    id="sidebar"
    class="fixed top-[46px] z-40 w-full min-w-[235px] bg-white text-xs leading-3 md:top-[82px] lg:relative lg:top-0 lg:z-10 lg:mb-8 lg:w-[235px]"
  >
    <button
      class="flex w-full items-center gap-1 bg-grey-900 px-4 py-2.5 lg:hidden"
      @click="toggleOpenMobile"
    >
      <span class="font-bold text-white">
        {{ defaultMainMenuName || sectionName }}
      </span>
      <span
        :class="{
          'animate-bounce-menu': !openMobileMenu,
        }"
      >
        <IconChevronDown
          class="w-5 text-p-400 duration-300"
          :class="{
            'rotate-180': openMobileMenu,
          }"
        />
      </span>
    </button>

    <div
      :class="{ 'h-fit pb-28': openMobileMenu, 'h-0': !openMobileMenu }"
      class="absolute z-10 flex max-h-[calc(100vh-105px)] w-full flex-col overflow-hidden overflow-y-auto bg-grey-100 shadow-file transition-all duration-300 lg:sticky lg:top-[153px] lg:bg-white lg:shadow-none xl:top-[105px]"
    >
      <client-only>
        <div
          v-for="menu in menus"
          :key="menu.name"
          class="relative flex w-full flex-col items-center font-medium before:absolute before:bottom-0 before:left-3.5 before:right-3.5 before:block before:h-px before:content-[''] lg:before:hidden"
          :class="{
            'bg-grey-300 text-s-900': isMenuActive(menu.name),
            'text-s-700': !isMenuActive(menu.name),
          }"
        >
          <div v-if="hasChildrenByAccess(menu)" class="flex w-full">
            <div
              class="w-1 bg-p-500"
              :class="{
                'opacity-0': !isMenuActive(menu.name),
              }"
            />
            <component
              :is="ItemComponent(menu)"
              v-bind="itemProps(menu)"
              class="flex w-full items-center justify-between gap-2 px-3.5 py-2.5"
              @click="toggleMenu(menu.name)"
            >
              <span class="flex items-center gap-2 font-medium">
                <span class="h-5 w-5">
                  <Icon
                    v-if="menu.icon"
                    :name="menu.icon"
                    class="h-5 w-5 lg:text-inherit"
                  />
                </span>
                <span class="text-left">{{ menu.label }}</span>
              </span>

              <IconChevronDown
                v-if="!menu.link"
                class="w-full min-w-[22px] max-w-[22px] duration-500"
                :class="{ 'rotate-180': menu.open }"
              />
            </component>
          </div>

          <NuxtLink
            v-else
            no-prefetch
            :to="menu.to"
            class="flex w-full items-center justify-between gap-2 px-3.5 py-2.5"
          >
            <div class="flex items-center gap-2">
              <div class="h-5 w-5">
                <Icon v-if="menu.icon" :name="menu.icon" class="h-5 w-5" />
              </div>
              <div class="text-left">{{ menu.label }}</div>
            </div>

            <IconChevronDown
              class="w-full min-w-[22px] max-w-[22px] opacity-0"
            />
          </NuxtLink>

          <div
            :class="{
              'unfold-animation': isOpenSubmenu(menu),
              'fold-animation': !isOpenSubmenu(menu),
            }"
            class="submenu flex w-full flex-col bg-grey-300 lg:bg-s-50"
          >
            <div v-for="subitem in menu.children" :key="subitem.name">
              <a
                v-if="subitem.externalLink"
                :href="subitem.to"
                target="_blank"
                class="flex bg-[#020d1d]"
              >
                <div class="flex items-center gap-1.5 py-3 pl-6">
                  <Icon :name="subitem.icon" class="h-5 w-5 text-p-900" />
                  <span class="text-white">
                    {{ subitem.name }}
                  </span>
                </div>
              </a>
              <NuxtLink
                v-else
                no-prefetch
                :to="subitem.to"
                class="flex hover:bg-grey-100"
                :class="{
                  'bg-grey-400 lg:bg-grey-100':
                    isSubMenuActive(subitem.page) && isMenuActive(menu.name),
                }"
              >
                <div
                  class="w-1 bg-p-500"
                  :class="{
                    'opacity-0':
                      !isSubMenuActive(subitem.page) ||
                      !isMenuActive(menu.name),
                  }"
                />
                <span class="py-3 pl-11 capitalize">
                  {{ subitem.name }}
                </span>
              </NuxtLink>
            </div>
          </div>
        </div>
      </client-only>
    </div>
  </div>
</template>

<script setup lang="ts">
import Icon from '~/components/common/Icon.vue'
import IconChevronDown from '~/assets/icons/ChevronDown.svg'
import { useMenuStore } from '~/stores/menu'
import { useSidebarStore } from '~/stores/sidebar'
import { useMembershipsStore } from '~/stores/memberships'
import { useAuthStore } from '~/stores/auth'

/*-------------------------------------
  Props & Refs
-------------------------------------*/
const props = withDefaults(
  defineProps<{
    menus: Array<any>
    activeMenu?: string
    activeSubMenu?: string
    defaultMainMenuName?: string
  }>(),
  {
    activeMenu: 'home',
  },
)

const openMobileMenu = ref(false)
const sectionName = ref('')
const prevScrollPos = ref(0)
const mediaQuery = ref<MediaQueryList | null>(null)

const sidebarStore = useSidebarStore()
const menuStore = useMenuStore()
const authStore = useAuthStore()
const membershipsStore = useMembershipsStore()

const { isTablet, isMobile } = useScreenSize()

const ItemComponent = (menu: any) => {
  return menu.link ? resolveComponent('NuxtLink') : 'button'
}
const itemProps = (menu: any) => {
  return menu.link ? { to: menu.link } : {}
}

const isOpenSubmenu = (menu: any) => {
  if (
    menu.name === 'price-dashboard' &&
    (!authStore.authenticated ||
      !membershipsStore.activePriceDashboardMembership)
  ) {
    return false
  }
  return (
    menu.children?.length > 0 &&
    menu.open &&
    (!menu.isPrivate || authStore.authenticated)
  )
}

const getIsMobile = () => {
  openMobileMenu.value = mediaQuery.value?.matches ?? false
}

const toggleOpenMobile = () => {
  openMobileMenu.value = !openMobileMenu.value
}

const toggleMenu = (menuName: string) => {
  sidebarStore.toggleMenu(menuName)
}

const isMenuActive = (menuName: string) => {
  return props.activeMenu.toLowerCase() === menuName.toLowerCase()
}

const hasChildrenByAccess = (menu: any) => {
  if (menu.name === 'price-dashboard') {
    return membershipsStore.activePriceDashboardMembership
  }
  return (
    menu.children?.length > 0 && (!menu.isPrivate || authStore.authenticated)
  )
}

const isSubMenuActive = (subMenuName: string) => {
  if (props.activeSubMenu?.toLowerCase() === subMenuName?.toLowerCase()) {
    return true
  }
  const activeSubMenu = props.activeSubMenu.replaceAll('-', ' ')
  return activeSubMenu === subMenuName?.toLowerCase()
}

const scrollFunction = () => {
  const currentScrollPos = window.scrollY
  const navbar = document.getElementById('navbar')
  const navbarHeight = navbar?.offsetHeight ?? 0
  const sidebar = document.getElementById('sidebar')
  const sidebarHeight = sidebar?.offsetHeight ?? 0
  const totalHeight = navbarHeight + sidebarHeight

  if (
    prevScrollPos.value < currentScrollPos &&
    currentScrollPos > totalHeight
  ) {
    if (navbar) navbar.style.top = `-${navbarHeight}px`
    if (sidebar) sidebar.style.top = `-${totalHeight}px`
  }

  if (
    prevScrollPos.value > currentScrollPos &&
    currentScrollPos >= totalHeight
  ) {
    if (navbar) navbar.style.top = '0'
    if (sidebar) sidebar.style.top = `${navbarHeight}px`
  }

  prevScrollPos.value = currentScrollPos
}

/*-------------------------------------
  Lifecycle
-------------------------------------*/
onMounted(() => {
  mediaQuery.value = window.matchMedia('(min-width: 1024px)')
  const activeMenu = props.menus.find((menu) => menu.name === props.activeMenu)
  sectionName.value = activeMenu?.section ?? ''
  getIsMobile()

  mediaQuery.value.addEventListener('change', getIsMobile)

  if (activeMenu) {
    sidebarStore.openMenu(activeMenu.name)
    menuStore.setActiveMenu({
      activeMenu,
      activeSubMenu: props.activeSubMenu,
    })
  }
  if (isTablet.value || isMobile.value) {
    window.onscroll = scrollFunction
  }
})

onUnmounted(() => {
  mediaQuery.value?.removeEventListener('change', getIsMobile)
})
</script>

<style scoped>
.submenu {
  transition: max-height 0.3s ease-out;
  overflow: hidden;
}

.fold-animation {
  max-height: 0;
}

.unfold-animation {
  max-height: 300px;
}

#sidebar {
  transition: top 0.3s ease;
}
</style>
